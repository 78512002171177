import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IApiResponse } from '@app/shared/model/api-response.model';

import { takeUntil } from 'rxjs';
import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { SupportService } from 'src/app/core/services/support.service';
import { SubscriptionDisposer } from 'src/app/shared/helpers/subscription-disposer';

@Component({
  selector: 'app-ticket-upload-tax-invoice-modal',
  templateUrl: './ticket-upload-tax-invoice-modal.component.html',
})
export class TicketUploadTaxInvoiceModalComponent
  extends SubscriptionDisposer
  implements OnInit
{
  @ViewChild('invoiceUploadInput') invoiceUploadInput!: ElementRef;
  @ViewChild('closeModal') closeModal: any;
  invoiceID: any;
  invoiceType: any;

  invoiceImageUrl: any;
  invoiceFormData: any;
  invoiceToUpload: any;
  invoieFileName: any = '';
  invoiceTicketDetails: any;
  invoiceOrderNumber: string;
  finalTotal: any;

  constructor(
    private generalService: GeneralService,
    private supportService: SupportService,
    private storageService: StorageService,
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: {
      invoiceID: any;
      invoiceType: any;
      invoiceAmount: number;
      orderNumber: string;
    }
  ) {
    super();
    this.invoiceType = data?.invoiceType ? data.invoiceType : '';
    this.invoiceID = data?.invoiceID ? data.invoiceID : 0;
    this.finalTotal = data?.invoiceAmount ? data?.invoiceAmount : 0;
    this.invoiceOrderNumber = data?.orderNumber ? data?.orderNumber : '';
  }

  ngOnInit(): void {}

  handleFileInput(event: any): void {
    const file: File = event.target.files[0];
    // this.invoieFileName = file.name;
    if (file) {
      if (file.type == 'application/pdf') {
        this.invoieFileName = file.name; // Only set the filename for valid PDF files
        this.invoiceFormData = new FormData();
        this.invoiceFormData.append('request_invoice', file);
        this.invoiceToUpload = event.target.files[0];
        if (this.invoiceToUpload) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.invoiceImageUrl = event.target.result;
            this.invoiceUploadInput.nativeElement.value = '';
          };
          reader.readAsDataURL(this.invoiceToUpload);
        }
      } else {
        // Clear the filename, file preview, and file input field if the file is not a PDF
        this.clearFileSelection();
        this.invoiceUploadInput.nativeElement.value = '';
        this.generalService.displayError('Please select only pdf file');
      }
    } else {
      // Handle the case where no file is selected
      this.clearFileSelection();
      this.invoiceUploadInput.nativeElement.value = '';
      this.generalService.displayError('Please select file');
    }
  }

  // Helper function to clear file selection data
  clearFileSelection(): void {
    this.invoiceUploadInput.nativeElement.value = ''; // Reset the file input
    this.invoiceFormData = new FormData(); // Reset the form data
    this.invoieFileName = ''; // Clear the filename
    this.invoiceImageUrl = ''; // Clear the file preview URL
    this.invoiceToUpload = null; // Reset the file to upload
  }

  uploadInvoiceFile(invoiceID: any): void {
    this.invoiceFormData.append('request_invoice_id', invoiceID);
    this.invoiceFormData.append(
      'seller_id',
      this.storageService.getCookie('userID')
    );
    this.supportService
      .uploadInvoiceFile(this.invoiceFormData)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res: IApiResponse) => {
          if (res) {
            this.generalService.displaySuccess(res?.message);
            this.removeTicketImage();
            this.closeMdl({ update: true });
          } else {
            this.generalService.displayError(res.message);
          }
        },
        error: (error) => {
          this.generalService.displayError(error?.error?.message);
        },
      });
  }

  removeTicketImage(): void {
    this.invoiceImageUrl = '';
    this.invoiceToUpload = '';
    this.invoiceFormData = new FormData();
    this.invoieFileName = '';
  }

  closeMdl(data?: any): void {
    this.dialogRef.close(data);
  }

  sendInvoice(): void {
    if (this.invoiceToUpload) {
      this.uploadInvoiceFile(this.invoiceID);
    } else {
      this.generalService.displayError('Please upload invoice');
    }
  }

  reUpload(): void {
    this.invoiceUploadInput.nativeElement.value = '';
    this.generalService.displayError('Please select file');
  }
}
