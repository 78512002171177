<div
  class="custom-dialog-modal modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable"
  role="document"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">New Ticket</h4>
      <button type="button" class="close" (click)="close()">
        <img src="assets/images/close-outline.svg" alt="close" />
      </button>
    </div>
    <div class="modal-body">
      <div class="add-new-ticket-block" [formGroup]="ticketData">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Subject"
            formControlName="subject"
          />
          <div
            *ngIf="f['subject'].invalid && f['subject'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['subject'].errors && f['subject'].errors['required']"
              class="required-note text-danger"
            >
              Subject is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <ng-select2
            class="select2-control"
            name="order"
            id="allOrderListID"
            [data]="allOrderList"
            [placeholder]="'Select Order / Reference Number'"
            formControlName="order"
          >
          </ng-select2>
          <div
            *ngIf="f['order'].invalid && f['order'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['order'].errors && f['order'].errors['required']"
              class="required-note text-danger"
            >
              Order/ Reference number is required.
            </div>
          </div>
        </div>

        <div class="form-group">
          <ng-select2
            class="select2-control"
            name="item"
            id="allItemListID"
            [data]="allItemList"
            (valueChanged)="getSelectedItem($event)"
            [placeholder]="'Select Item'"
            formControlName="item"
          >
          </ng-select2>
          <div
            *ngIf="f['item'].invalid && f['item'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="f['item'].errors && f['item'].errors['required']"
              class="required-note text-danger"
            >
              item is required.
            </div>
          </div>
        </div>
        <div class="form-group">
          <angular-editor formControlName="description" [config]="config">
          </angular-editor>
          <!-- <div
            *ngIf="f['description'].invalid && f['description'].touched"
            class="cross-validation-error-message"
          >
            <div
              *ngIf="
                f['description'].errors && f['description'].errors['required']
              "
              class="required-note text-danger"
            >
              Description is required.
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="bottom-btnrow">
        <button
          class="btn t-primary-btn for-disabled"
          (click)="save()"
          [disabled]="
            ticketData.get('description').invalid ||
            !ticketData.get('description').value.trim()
          "
        >
          <img src="assets/images/paper-plane-outline.svg" alt="send" /> Send
        </button>
      </div>
    </div>
  </div>
</div>
