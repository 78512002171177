import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountDeactivationService {
  public deactivationSubject = new Subject<void>();

  // Observable for other components to subscribe to
  deactivation$ = this.deactivationSubject.asObservable();

  // Call this method when deactivation occurs
  notifyDeactivation(data: any) {
    this.deactivationSubject.next(data);
  }
}
