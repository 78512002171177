import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { GeneralService } from 'src/app/core/services/general.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { VendorService } from 'src/app/core/services/vendor.service';
import { AccountDeactivationService } from '@app/shared/services/account-deactivation.service';

import { SubscriptionDisposer } from '../../helpers/subscription-disposer';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends SubscriptionDisposer implements OnInit {
  menuID: any = 1;
  subMenuID: any = 1;
  userName = '';
  selectedIndex = 0;

  vendorStatus: number;
  access: any = {
    isDashboard: true,
    isProducts: true,
    isServices: true,
    isPromotions: true,
    isFinances: true,
    isDataTransfer: true,
    isSupport: true,
    isCustomReports: true,
    isAccountSetting: true,
  };
  dashboardAccess: any = {
    isAnalytics: true,
    isCustomReport: true,
  };
  productAccess: any = {
    isList: true,
    isAddProduct: true,
    isOrder: true,
    isProductReturn: true,
    isCrossSellUpsell: true,
  };
  serviceAccess: any = {
    isList: true,
    isAddService: true,
    isOrder: true,
    isCrossSellUpsell: true,
  };
  promotionAccess: any = {
    isList: true,
    isAdd: true,
    isPurchase: true,
  };
  accountAccess: any = {
    isProfile: true,
    isCompany: true,
    isStore: true,
    isTeam: true,
  };
  walletAccess: any = {
    isDashboard: true,
    isInvoice: true,
    isShipping: true,
    isCommission: true,
  };
  dataImportAccess: any = {
    isImport: true,
    isExport: true,
    isImportHistory: true,
  };
  supportAccess = {
    isInvoiceRequest: true,
    isAdminTickets: true,
  };
  menuList: any = [];
  recentPage: any;
  recentPageID: any;

  constructor(
    private router: Router,
    public generalService: GeneralService,
    public vendorService: VendorService,
    private Location: Location,
    private storageService: StorageService,
    private deactivationService: AccountDeactivationService
  ) {
    super();
    this.recentPage = this.Location.path();
    if (this.menuID) {
      this.checkMenuId();
    }

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        for (let i = 0; i < this.menuList.length; i++) {
          const subMenu = this.menuList[i].subList.find((s: any) =>
            s.possibleSubLinks.includes(event.url.split('?')[0])
          );
          if (subMenu) {
            this.menuID = this.menuList[i].id;
            this.subMenuID = subMenu.subID;
            break;
          } else {
            if (this.menuList[i].link === event.url.split('?')[0]) {
              this.menuID = this.menuList[i].id;
              this.subMenuID = 0;
            }
          }
        }
      }
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.recentPage = this.Location.path();
    if (this.menuID) {
      this.checkMenuId();
    }
  }

  getMenuList(): void {
    // console.log('in menu list');
    this.menuList.push(
      {
        id: 1,
        name: 'Dashboard',
        img: 'assets/images/dashboard-icon.svg',
        subList: [
          {
            subID: 1,
            subName: 'Analytics',
            subLink: '/home/dashboard/analytics',
            possibleSubLinks: ['/home/dashboard/analytics'],
            isSubShow: this.dashboardAccess?.isAnalytics,
          },
          {
            subID: 2,
            subName: 'Reports',
            subLink: '/home/dashboard/custom-reports',
            possibleSubLinks: ['/home/dashboard/custom-reports'],
            isSubShow: this.dashboardAccess?.isCustomReport,
          },
          // {
          //   subID: 3,
          //   subName: 'CRM',
          //   subLink: '/home/dashboard/crm',
          //   possibleSubLinks: ['/home/dashboard/crm']
          // }
        ],
        link: '/home/dashboard',
        isExpanded: false,
        hasOwnPage: true,
        isShow: this.access?.isDashboard,
      },
      {
        id: 2,
        name: 'Products',
        img: 'assets/images/products-icon.svg',
        subList: [
          {
            subID: 1,
            subName: 'Product List',
            subLink: '/home/product/products',
            possibleSubLinks: ['/home/product/products'],
            isSubShow: this.productAccess?.isList,
          },
          {
            subID: 2,
            subName: 'Add New Product',
            subLink: '/home/product/add-product',
            possibleSubLinks: ['/home/product/add-product'],
            isSubShow: this.productAccess?.isAddProduct,
          },
          {
            subID: 3,
            subName: 'Order List',
            subLink: '/home/product/orders',
            possibleSubLinks: [
              '/home/product/orders',
              '/home/product/order-details',
            ],
            isSubShow: this.productAccess?.isOrder,
          },
          {
            subID: 4,
            subName: 'Returns',
            subLink: '/home/product/return',
            possibleSubLinks: [
              '/home/product/return',
              '/home/product/return-item',
            ],
            isSubShow: this.productAccess?.isProductReturn,
          },
          // {
          //   subID: 5,
          //   subName: 'Cross-sell / Upsell',
          //   subLink: '/home/product/cross-sell-upsell',
          //   possibleSubLinks: ['/home/product/cross-sell-upsell'],
          //   isSubShow: this.productAccess?.isCrossSellUpsell,
          // },
        ],
        link: '/home/product/products',
        isExpanded: false,
        isShow: this.access?.isProducts,
      },
      {
        id: 3,
        name: 'Services',
        img: 'assets/images/searvice-icon.svg',
        subList: [
          {
            subID: 1,
            subName: 'Service List',
            subLink: '/home/service/services',
            possibleSubLinks: ['/home/service/services'],
            isSubShow: this.serviceAccess?.isList,
          },
          {
            subID: 2,
            subName: 'Add New Service',
            subLink: '/home/service/add-service',
            possibleSubLinks: ['/home/service/add-service'],
            isSubShow: this.serviceAccess?.isAddService,
          },
          {
            subID: 3,
            subName: 'Order List',
            subLink: '/home/service/orders',
            possibleSubLinks: [
              '/home/service/orders',
              '/home/service/order-details',
            ],
            isSubShow: this.serviceAccess?.isOrder,
          },
          // {
          //   subID: 4,
          //   subName: 'Cross-sell / Upsell',
          //   subLink: '/home/service/cross-sell-upsell',
          //   possibleSubLinks: ['/home/service/cross-sell-upsell'],
          //   isSubShow: this.serviceAccess?.isCrossSellUpsell,
          // },
        ],
        link: '/home/service/services',
        isExpanded: false,
        isShow: this.access?.isServices,
      },
      {
        id: 4,
        name: 'Promotions',
        img: 'assets/images/promotion-icon.svg',
        subList: [
          {
            subID: 1,
            subName: 'Coupon List',
            subLink: '/home/promotions/coupons',
            possibleSubLinks: ['/home/promotions/coupons'],
            isSubShow: this.promotionAccess?.isList,
          },
          {
            subID: 2,
            subName: 'New Coupon',
            subLink: '/home/promotions/add-coupon',
            possibleSubLinks: ['/home/promotions/add-coupon'],
            isSubShow: this.promotionAccess?.isAdd,
          },
          {
            subID: 3,
            subName: 'Ad Purchases',
            subLink: '',
            possibleSubLinks: [''],
            isSubShow: this.promotionAccess?.isPurchase,
          },
        ],
        link: '/home/promotions/coupons',
        isExpanded: false,
        isShow: this.access?.isPromotions,
      },
      {
        id: 5,
        name: 'Finances',
        img: 'assets/images/finance-icon.svg',
        subList: [
          {
            subID: 1,
            subName: 'My Wallet',
            subLink: '/home/finances/wallet',
            possibleSubLinks: ['/home/finances/wallet'],
            isSubShow: this.walletAccess?.isDashboard,
          },
          // {
          //   subID: 2,
          //   subName: 'Payouts',
          //   subLink: '/home/finances/payouts',
          //   possibleSubLinks: []
          // },
          {
            subID: 3,
            subName: 'Invoice List',
            subLink: '/home/finances/invoices',
            possibleSubLinks: [
              '/home/finances/invoices',
              '/home/finances/view-invoice',
            ],
            isSubShow: this.walletAccess?.isInvoice,
          },
          {
            subID: 5,
            subName: 'Commission',
            subLink: '/home/finances/commision',
            possibleSubLinks: ['/home/finances/commision'],
            isSubShow: this.walletAccess?.isCommission,
          },
          // {
          //   subID: 6,
          //   subName: 'Credit Memo',
          //   subLink: '/home/finances/credit-memo',
          //   possibleSubLinks: ['/home/finances/credit-memo']
          // }
        ],
        link: '/home/finances/invoices',
        isExpanded: false,
        isShow: this.access?.isFinances,
      },
      {
        id: 10,
        name: 'Data Transfer',
        img: 'assets/images/data-transfer-icon.svg',
        subList: [
          {
            subID: 1,
            subName: 'Import',
            subLink: '/home/data-transfer/import',
            possibleSubLinks: ['/home/data-transfer/import'],
            isSubShow: this.dataImportAccess?.isImport,
          },
          {
            subID: 2,
            subName: 'Import History',
            subLink: '/home/data-transfer/import-history',
            possibleSubLinks: ['/home/data-transfer/import-history'],
            isSubShow: this.dataImportAccess?.isImportHistory,
          },
          {
            subID: 3,
            subName: 'Export',
            subLink: '/home/data-transfer/export',
            possibleSubLinks: ['/home/data-transfer/export'],
            isSubShow: this.dataImportAccess?.isExport,
          },
        ],
        link: '/home/data-transfer/import',
        isExpanded: false,
        isShow: this.access?.isDataTransfer,
      },
      // {
      //   id: 6,
      //   name: 'Reports',
      //   img: 'assets/images/reports-icon.svg',
      //   subList: [{
      //     subID: 1,
      //     subName: 'Orders',
      //     subLink: '/home/reports/orders',
      //     possibleSubLinks: ['/home/reports/orders']
      //   },
      //   {
      //     subID: 2,
      //     subName: 'Tax',
      //     subLink: '', //home/reports/tax
      //     possibleSubLinks: [''] //home/reports/tax
      //   },
      //   {
      //     subID: 3,
      //     subName: 'Coupon Usage',
      //     subLink: '/home/reports/coupon-usage',
      //     possibleSubLinks: ['/home/reports/coupon-usage']
      //   },
      //   {
      //     subID: 4,
      //     subName: 'Stocks',
      //     subLink: '/home/reports/stocks',
      //     possibleSubLinks: ['/home/reports/stocks']
      //   },
      //   {
      //     subID: 5,
      //     subName: 'Invoiced Sales',
      //     subLink: '/home/reports/invoiced-sales',
      //     possibleSubLinks: ['/home/reports/invoiced-sales']
      //   },
      //   {
      //     subID: 6,
      //     subName: 'Abandon Cart',
      //     subLink: '/home/reports/abandon-cart',
      //     possibleSubLinks: ['/home/reports/abandon-cart']
      //   },
      //   {
      //     subID: 7,
      //     subName: 'Commission Paid',
      //     subLink: '/home/reports/commission-paid',
      //     possibleSubLinks: ['/home/reports/commission-paid']
      //   }
      //   ],
      //   link: '/home/reports/orders',
      //   isExpanded: false
      // },
      {
        id: 9,
        name: 'Support',
        img: 'assets/images/support-icon.svg',
        subList: [
          //   {
          //   subID: 1,
          //   subName: 'Tickets',
          //   subLink: '/home/support/tickets',
          //   possibleSubLinks: ['/home/support/tickets']
          // },
          {
            subID: 2,
            subName: 'Invoice Request',
            subLink: '/home/support/invoice-request-tickets',
            possibleSubLinks: ['/home/support/invoice-request-tickets'],
            isSubShow: this.supportAccess?.isInvoiceRequest,
          },
          {
            subID: 3,
            subName: 'Admin Support',
            subLink: '/home/support/admin-tickets',
            possibleSubLinks: ['/home/support/admin-tickets'],
            isSubShow: this.supportAccess?.isAdminTickets,
          },
        ],
        link: '/home/support/invoice-request-tickets',
        isExpanded: false,
        isShow: this.access?.isSupport,
      },
      {
        id: 7,
        name: 'Account Setting',
        img: 'assets/images/settings-icon.svg',
        subList: [
          {
            subID: 1,
            subName: 'Profile',
            subLink: '/home/account-settings/profile',
            possibleSubLinks: ['/home/account-settings/profile'],
            isSubShow: this.accountAccess?.isProfile,
          },
          {
            subID: 2,
            subName: 'Company',
            subLink: '/home/account-settings/company',
            possibleSubLinks: ['/home/account-settings/company'],
            isSubShow: this.accountAccess?.isCompany,
          },
          {
            subID: 3,
            subName: 'Store',
            subLink: '/home/account-settings/store',
            possibleSubLinks: ['/home/account-settings/store'],
            isSubShow: this.accountAccess?.isStore,
          },
          {
            subID: 4,
            subName: 'Account Users',
            subLink: '/home/account-settings/teams',
            possibleSubLinks: [
              '/home/account-settings/teams',
              '/home/roles',
              '/home/account-settings/manage-team',
            ],
            isSubShow: this.accountAccess?.isTeam,
          },
          {
            subID: 5,
            subName: 'Change Password',
            subLink: '/home/account-settings/change-password',
            possibleSubLinks: ['/home/account-settings/change-password'],
            isSubShow: true,
          },
        ],
        link: '/home/account-settings/profile',
        isExpanded: false,
        isShow: this.access?.isAccountSetting,
      }
    );
    // console.log(this.menuList);
  }

  checkMenuId(): void {
    if (this.recentPage == '/home/dashboard') {
      this.menuID = 1;
      this.subMenuID = 0;
    } else if (this.recentPage == '/home/account-settings/profile') {
      this.menuID = 7;
      this.subMenuID = 1;
    } else if (this.recentPage == '/home/account-settings/company') {
      this.menuID = 7;
      this.subMenuID = 2;
    } else if (this.recentPage == '/home/account-settings/store') {
      this.menuID = 7;
      this.subMenuID = 3;
    } else if (this.recentPage == '/home/account-settings/teams') {
      this.menuID = 7;
      this.subMenuID = 4;
    }
  }

  ngOnInit(): void {
    // deactivation subscriber
    this.deactivationService.deactivationSubject.subscribe((data: any) => {
      if (data && data == 'deactivated') {
        console.log('in subscriber  - deactivationSubject - ' + data);
        this.setModuleAccess(); // Call method to reload menu based on new data
      }
    });

    this.vendorService.getUserDetail().subscribe((res) => {
      if (res) {
        this.vendorStatus = res?.status || 0;
        // this.setModuleAccess();
      }
    });
    this.setModuleAccess();
    for (let i = 0; i < this.menuList.length; i++) {
      const subMenu = this.menuList[i].subList.find((s: any) =>
        s.possibleSubLinks.includes(this.router.url.split('?')[0])
      );
      if (subMenu) {
        this.menuID = this.menuList[i].id;
        this.subMenuID = subMenu.subID;
        this.menuList[i].isExpanded = !this.menuList[i].isExpanded;
        break;
      } else {
        if (this.menuList[i].link === this.router.url.split('?')[0]) {
          this.menuID = this.menuList[i].id;
          this.subMenuID = 0;
          this.menuList[i].isExpanded = !this.menuList[i].isExpanded;
        }
      }
    }
    this.getHeaderEmailNotification();

    // Subscribe to menu state updates
    this.vendorService.menuState$.subscribe((state) => {
      this.menuID = state.menuID;
      this.subMenuID = state.subMenuID;

      // Expand the correct menu based on the state
      this.menuList.forEach((menu) => {
        menu.isExpanded = menu.id === this.menuID;
      });
    });
  }

  openSubList(index: any, isExpanded: any, id: any, event: any): void {
    console.log('index, isExpanded, id, event');
    console.log(index, isExpanded, id, event);

    if (event?.ctrlKey) {
      return;
    }
    if (this.vendorStatus === 2) {
      this.router.navigate(['/home/dashboard']);
      this.generalService.displayError('Your application is under review!');
      return;
    }

    this.menuList.map((item: any) => {
      if (item.id == id) {
        if (!item?.hasOwnPage) {
          this.menuID = id;
          item.isExpanded = !isExpanded;
        } else {
          this.menuID = id;
          item.isExpanded = !isExpanded;
        }
      } else {
        item.isExpanded = false;
      }
    });
  }

  subLinkClick(isExpanded: any, id: any, event: any): void {
    if (event?.ctrlKey) {
      return;
    }
    if (this.vendorStatus === 2) {
      this.router.navigate(['/home/dashboard']);
      this.generalService.displayError('Your application is under review!');
      return;
    }

    this.menuList.map((item: any) => {
      if (item.id == id) {
        item.isExpanded = !isExpanded;
      }
    });
    return;
  }

  routeToLink(link: any, obj: any, event: any): void {
    if (event?.ctrlKey) {
      return;
    }
    if (link) {
      if (obj.id !== this.menuID) {
        this.subMenuID = 1;
        this.router.navigate([link]);
      } else {
        if (obj?.hasOwnPage) {
          this.router.navigate([link]);
        }
      }
    } else {
      this.subMenuID = 1;
      this.generalService.displayWarning(`${obj.name} Coming Soon !!`);
    }
  }

  routeToSubLink(subLink: any, obj: any, event: any): void {
    if (event?.ctrlKey) {
      return;
    }
    this.subMenuID = obj.subID;
    if (subLink) {
      this.router.navigate([subLink]);
    } else {
      this.generalService.displayWarning(`${obj.subName} Coming Soon !!`);
    }
  }

  getHeaderEmailNotification(): void {
    this.vendorService.getHeaderEmailNotification().subscribe((val: string) => {
      if (val) {
        this.menuID = 9;
        this.subMenuID = 1;
        this.menuList[7].isExpanded = true;
      }
    });
  }

  setModuleAccess(): void {
    // console.log(
    //   'role service_acc: ' +
    //     this.storageService.getRoleAccessDetails('service_access')
    // );
    // console.log(
    //   'role module_access: ' +
    //     this.storageService.getRoleAccessDetails('module_access')
    // );

    if (this.storageService.getRoleAccessDetails('module_access') == 1) {
      if (this.storageService.getRoleAccessDetails('product_access') == 1) {
        this.access.isProducts = true;
      }
      if (this.storageService.getRoleAccessDetails('promotions_access') == 1) {
        this.access.isPromotions = true;
      }
      if (this.storageService.getRoleAccessDetails('reports_access') == 1) {
        this.access.isCustomReports = true;
      }
      if (this.storageService.getRoleAccessDetails('service_access') == 1) {
        this.access.isServices = true;
      }
      if (
        this.storageService.getRoleAccessDetails('account_settings_access') == 1
      ) {
        this.access.isAccountSetting = true;
      }
      if (this.storageService.getRoleAccessDetails('finances_access') == 1) {
        this.access.isFinances = true;
      }
      if (
        this.storageService.getRoleAccessDetails('data_transfer_access') == 1
      ) {
        this.access.isDataTransfer = true;
      }
    } else if (this.storageService.getRoleAccessDetails('module_access') == 2) {
      if (this.storageService.getRoleAccessDetails('product_access') == 2) {
        this.access.isProducts = false;
      }
      if (this.storageService.getRoleAccessDetails('promotions_access') == 2) {
        this.access.isPromotions = false;
      }
      if (this.storageService.getRoleAccessDetails('reports_access') == 2) {
        this.access.isCustomReports = false;
      }
      if (this.storageService.getRoleAccessDetails('service_access') == 2) {
        this.access.isServices = false;
      }
      if (
        this.storageService.getRoleAccessDetails('account_settings_access') == 2
      ) {
        this.access.isAccountSetting = false;
      }
      if (this.storageService.getRoleAccessDetails('finances_access') == 2) {
        this.access.isFinances = false;
      }
      if (
        this.storageService.getRoleAccessDetails('data_transfer_access') == 2
      ) {
        this.access.isDataTransfer = false;
      }
    } else if (this.storageService.getRoleAccessDetails('module_access') == 3) {
      this.access.isFinances = false;
      this.access.isDataTransfer = false;
      this.access.isCustomReports = false;
      this.access.isPromotions = false;

      if (this.storageService.getRoleAccessDetails('product_access') != 2) {
        this.access.isProducts = true;
      }
      if (this.storageService.getRoleAccessDetails('service_access') != 2) {
        this.access.isServices = true;
      }
      if (
        this.storageService.getRoleAccessDetails('account_settings_access') != 2
      ) {
        this.access.isAccountSetting = true;
      }
    } else if (this.storageService.getRoleAccessDetails('module_access') == 4) {
      if (this.storageService.getRoleAccessDetails('product_access') == 2) {
        this.access.isProducts = false;
      }
      if (this.storageService.getRoleAccessDetails('promotions_access') == 2) {
        this.access.isPromotions = false;
      }
      if (this.storageService.getRoleAccessDetails('reports_access') == 2) {
        this.access.isCustomReports = false;
      }
      if (this.storageService.getRoleAccessDetails('service_access') == 2) {
        this.access.isServices = false;
      }
      if (
        this.storageService.getRoleAccessDetails('account_settings_access') == 2
      ) {
        this.access.isAccountSetting = false;
      }
      if (this.storageService.getRoleAccessDetails('finances_access') == 2) {
        this.access.isFinances = false;
      }
      if (
        this.storageService.getRoleAccessDetails('data_transfer_access') == 2
      ) {
        this.access.isDataTransfer = false;
      }
      if (
        this.storageService.getRoleAccessDetails('product_access') == 2 ||
        this.storageService.getRoleAccessDetails('product_access') == 3 ||
        this.storageService.getRoleAccessDetails('product_access') == 6
      ) {
        this.productAccess.isAddProduct = false;
      }
      if (
        this.storageService.getRoleAccessDetails('service_access') == 2 ||
        this.storageService.getRoleAccessDetails('service_access') == 3 ||
        this.storageService.getRoleAccessDetails('service_access') == 6
      ) {
        this.serviceAccess.isAddService = false;
      }
      if (
        this.storageService.getRoleAccessDetails('promotions_access') == 2 ||
        this.storageService.getRoleAccessDetails('promotions_access') == 3 ||
        this.storageService.getRoleAccessDetails('promotions_access') == 6
      ) {
        this.promotionAccess.isAdd = false;
      }
    } else {
      this.access.isProducts = true;
      this.access.isServices = true;
      this.access.isAccountSetting = true;
      this.access.isFinances = true;
      this.access.isDataTransfer = true;
      this.access.isPromotions = true;
      this.access.isCustomReports = true;
    }
    if (
      this.storageService.getRoleAccessDetails('role_name') != 'Super Admin'
    ) {
      this.accountAccess.isTeam = false;
    }
    this.menuList = [];
    this.getMenuList();
  }
}
